<template>
    <div @click="toggleMenu" ref="bt-menu" class="bt-menu" title="Menu">
        <span class="hamburguer">
            <span class="bar bar-1"></span>
            <span class="bar bar-2"></span>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        menuStatus: {
            type:Boolean,
        }
    },

    data() {
        return {}
    },

    watch: {
        menuStatus(menuStatus) {
            const menuClassList = this.$refs['bt-menu'].querySelector('.hamburguer').classList;
            menuStatus ? menuClassList.add('menu-open') : menuClassList.remove('menu-open');
        }
    },

    methods: {
        toggleMenu() {
            this.$emit('update:menuStatus', !this.menuStatus);
        }
    },
}
</script>

<style lang="scss" scoped>
$hamburger-button-size     : 40px;
$hamburger-button-radius   : 4px;
$hamburger-bar-color       : #fff;
$hamgurger-bar-width       : 22px;
$hamgurger-bar-height      : 2px;
$hamgurger-bar-spacing     : 10px;
$hamgurger-transition-speed: 0.4s;

.bt-menu{
    width: $hamburger-button-size;
    height: $hamburger-button-size;
    text-align: center; 
    cursor: pointer;
    padding-top: 5px;
    position: relative;
    z-index: 12;
}

.hamburguer {
    width: $hamgurger-bar-width;
    height: ($hamgurger-bar-height * 3) + ($hamgurger-bar-spacing * 2);
    transition: $hamgurger-transition-speed;
    position: relative;
    display: inline-block;
    .bar {
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        display: block;
        width: $hamgurger-bar-width;
        height: $hamgurger-bar-height;
        background: $hamburger-bar-color;
        transition: $hamgurger-transition-speed / 2;
        outline: 1px solid transparent;
    }

    .bar-1 {
        transform: rotate(0) translate3d(0, -($hamgurger-bar-height * 2), 0);
    }
    
    .bar-2 {
        transform: rotate(0) translate3d(0, ($hamgurger-bar-height * 4), 0);
    }

    &.menu-open {
        transform: rotate(180deg);
        .bar-1 { transform: rotate(45deg) translate3d(0, 0, 0); }
        .bar-2 { transform: rotate(-45deg) translate3d(0, 0, 0); }
    }
}

</style>