import apiClient from '@/apis/apiClient';

async function getCsrfToken() {
    try {
        const { data } = await apiClient.get('/getCsrfToken');
        return data;
    } catch (err) {
        console.log(err);
    }
}

export { getCsrfToken };