import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta';
import { getCsrfToken } from '@/apis/apiCsrf';
import { checkUser } from '@/apis/apiAuth';

Vue.use(VueMeta);
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
return originalPush.call(this, location).catch(err => err)
}

//   =======================================  Views  ======================================= 
const Home = () => import('@/views/Home.vue');
const Application = () => import('@/views/application/Application.vue');
const Events = () => import('@/views/event/Events.vue');
const EventDetail = () => import('@/views/event/EventDetail.vue');
const ContactUs = () => import('@/views/contact/ContactUs.vue')
const AdsContactUs = () => import('@/views/contact/AdsContactUs.vue')


const LoginForm = () => import('@/views/LoginForm.vue');
//   =======================================  UseCase  ======================================= 
const UseCases = () => import('@/views/usecase/UseCases.vue')
const SmartBuilding = () => import('@/views/usecase/solution/SmartBuilding.vue')
const SmartGym = () => import('@/views/usecase/solution/SmartGym.vue')
const SmartOffice = () => import('@/views/usecase/solution/SmartOffice.vue')
const SmartCamp = () => import('@/views/usecase/solution/SmartCamp.vue')
const SmartCare = () => import('@/views/usecase/solution/SmartCare.vue')
const SmartConstructionSite = () => import('@/views/usecase/solution/SmartConstructionSite.vue')
const ConstructionSite = () => import('@/views/usecase/solution/CaseStudyConstructionSite.vue')
//  =======================================  Admin  =======================================
const AdminOutline = () => import('@/views/admin/AdminOutline.vue')

// event
const GetEventList = () => import('@/views/admin/GetEvents.vue');
const CreateEvent = () => import('@/views/admin/EventEditor.vue');
const PutEvent = () => import('@/views/admin/EventEditor.vue');

// category
const GetCategories = () => import('@/views/admin/GetCategories.vue');
const CreateCategory = () => import('@/views/admin/CategoryEditor.vue');
const PutCategory = () => import('@/views/admin/CategoryEditor.vue');

// product
const GetProducts = () => import('@/views/products/Products.vue');
const GetAiraTrackPro = () => import('@/views/products/AiraTrackPro.vue');

// Privacy term

const privacyTerm = () => import('@/views/PrivacyTerm.vue')

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/privacyterm',
        name: 'PrivacyTerm',
        component: privacyTerm,
    },
    {
        path: '/application',
        name: 'Application',
        component: Application,
    },
    {
        path: '/events',
        name: 'Events',
        component: Events,
    },
    {
        path: '/events/:category/:eventTitle',
        name: 'EventDetail',
        component: EventDetail,
    },
    {
        path: '/contactUs',
        name: 'ContactUs',
        component: ContactUs,
    },
    {
        path: '/constructionSite/contactUs',
        name: 'AdsContactUs',
        component:AdsContactUs
    },
    {
        path: '/useCases',
        name: 'UseCases',
        component: UseCases
    },
    {
        path: '/useCases/smartBuilding',
        name: 'SmartBuilding',
        component: SmartBuilding
    },
    {
        path: '/useCases/smartGym',
        name: 'SmartGym',
        component: SmartGym
    },
    {
        path: '/useCases/smartOffice',
        name: 'SmartOffice',
        component: SmartOffice
    },
    {
        path: '/useCases/smartCamp',
        name: 'SmartCamp',
        component: SmartCamp
    },
    {
        path: '/useCases/smartCare',
        name: 'SmartCare',
        component: SmartCare
    },
    {
        path: '/useCases/smartConstructionSite',
        name: 'SmartConstructionSite',
        component: SmartConstructionSite
    },
    {   path: '/CaseStudy/ConstructionSite',
        name: 'ConstructionSite',
        component:ConstructionSite
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginForm,
    },
    {
        path: '/products',
        name: 'products',
        component: GetProducts,
    },
    {
        path: '/products/airatrack-pro',
        name: 'airaTrackPro',
        component: GetAiraTrackPro,
    },
    {
        path: '/admin',
        component: AdminOutline,
        children: [
            {
                path: '',
                name: 'getEvents',
                component: GetEventList,
                meta: { 
                    requiresAuth: true,
                    sidebarCategory: 'event',
                },
            },
            {
                path: 'createEvent',
                name: 'createEvent',
                component: CreateEvent,
                meta: { 
                    requiresAuth: true,
                    sidebarCategory: 'event',
                },
            },
            {
                path: 'putEvent',
                name: 'putEvent',
                component: PutEvent,
                meta: { 
                    requiresAuth: true,
                    sidebarCategory: 'event',
                },
            },
            {
                path: 'getCategories',
                name: 'getCategories',
                component: GetCategories,
                meta: { 
                    requiresAuth: true,
                    sidebarCategory: 'category',
                },
            },
            {
                path: 'createCategory',
                name: 'createCategory',
                component: CreateCategory,
                meta: { 
                    requiresAuth: true,
                    sidebarCategory: 'category',
                },
            },
            {
                path: 'putCategory',
                name: 'putCategory',
                component: PutCategory,
                meta: { 
                    requiresAuth: true,
                    sidebarCategory: 'category',
                },
            },
        ]
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    // scrollBehavior (to, from, savedPosition) {
    //     // return 期望滚动到哪个的位置
    //     if (savedPosition) {
    //         return savedPosition
    //     } else {
    //         return { x: 0, y: 0 }
    //     }
    // },
    mode: 'history',
    routes
})

router.beforeEach(async (to, from, next) => {
    // 讓後端每次都產生不一樣的 csrf token
    let csrfToken = await getCsrfToken();
    localStorage.setItem('csrf-token',csrfToken);

    if (to.meta.requiresAuth) {
        let result = await checkUser();
        result.status === 200 ? next() : router.push('/login');
    } else {
        next();
    }
});

export default router;
