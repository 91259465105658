<template>
    <section class="position-relative d-flex overflow-hidden contact">
        <b-container>
            <div class="row" >
                <div class="col-lg-12">
                    <div style="text-align:center;height:300px">
                        <h1 class="smart-Title page-h1 mb-0"> {{ $t("ContactUs.Slogan")}}</h1>
                        <router-link to="/contactUs" >
                            <button class="call-to-act">
                                {{ $t("Nav_contactUs")}}
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
        </b-container>
    </section>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>

.contact {
    background-color: $light-gray-color;
}


.call-to-act {
    cursor: pointer;
    border: unset;
    background-color: #ff991a;
    color: #ffffff;
    height: 45px;
    width: 250px;
    border-radius:  5px;
    font-size: 30px;
    font-weight: 400px;
    text-align: center;
    transition: 0.4s;
    &:hover {
        filter: brightness(1.2);
        color: #000000;
    }
}


@media screen and (max-width: 812px) {

}


@media screen and (max-width: 576px) {

}
</style>