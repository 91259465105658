import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: true,
    },
    mutations: {
        setState(state, {key, value}) {
        state[key] = value;
        }
    },
    actions: {
    },
    modules: {
    }
})
