
import Vue from 'vue';

// partical import bootstrap vue
import { 
  LayoutPlugin,
  CardPlugin,
  TablePlugin,
  TooltipPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  FormFilePlugin,
  FormTextareaPlugin
} from 'bootstrap-vue';

Vue.use(LayoutPlugin)
Vue.use(CardPlugin)
Vue.use(TablePlugin)
Vue.use(TooltipPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormTextareaPlugin)
