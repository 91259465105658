<template>
    <div class="position-relative w-100 innerBanner overflow-hidden"
        :style="bgImgStyle(imgType)" loading="lazy" >
    </div>
</template>

<script>
export default {
    props: {
        imgType: {
            type: String,
            default: 'application'
        } 
    },

    data() {
        return {
            bgImg: {
                application: require("@/assets/img/application/inner_banner.png"),
                event: require("@/assets/img/event/inner_banner.png"),
                smartBuilding :require("@/assets/img/usecase/smart-building.webp"),
                smartGym :require("@/assets/img/usecase/smart-gym.webp"),
                smartOffice :require("@/assets/img/usecase/smart-office.webp"),
                smartCare :require("@/assets/img/usecase/smart-care.webp"),
                smartCamp :require("@/assets/img/usecase/smart-camp.webp"),
                smartConstructionSite :require("@/assets/img/usecase/case-study-construction-site-3.png"),
            }
        }
    },

    methods: {
        bgImgStyle(imgType) {
            return {
                backgroundImage: `url(${this.bgImg[imgType]})`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.innerBanner{
    height: 420px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media screen and (max-width: 1024px) {
    .innerBanner{
        height: 300px;
    }
}


@media screen and (max-width: 576px) {
    .innerBanner{
        height: 250px;
    }
}
</style>