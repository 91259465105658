import Vue from 'vue'
import { 
  BIcon,
  BIconEnvelope,
  BIconLock,
  BIconListUl,
  BIconBookmarks,
  BIconChevronRight,
  BIconChevronLeft,
  BIconPlus,
  BIconTrash,
  BIconPencil,
} from 'bootstrap-vue'

Vue.component('BIcon', BIcon)
Vue.component('BIconEnvelope', BIconEnvelope)
Vue.component('BIconLock', BIconLock)
Vue.component('BIconListUl', BIconListUl)
Vue.component('BIconBookmarks', BIconBookmarks)
Vue.component('BIconChevronLeft', BIconChevronLeft)
Vue.component('BIconChevronRight', BIconChevronRight)
Vue.component('BIconPlus', BIconPlus)
Vue.component('BIconTrash', BIconTrash)
Vue.component('BIconPencil', BIconPencil)