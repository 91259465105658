import axios from 'axios';
// import store from '@/store/index';
import router from '@/router/index';

const client = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 2 * 60 * 1000,
    withCredentials: true,
    maxContentLength: 10 * 1000,
    maxBodyLength: 10 * 1000,
})

client.interceptors.request.use((config) => {
    config.headers['csrf-token'] = localStorage.getItem('csrf-token') || '';
    return config;
}, (error) => {
    return Promise.reject(error);
})

client.interceptors.response.use((response) => {
    return response.data;
}, (err) => {
    // need to remove
    // console.log('client interceptors response error', err);

    const fullPath = router.currentRoute.fullPath;
    const {response} = err;

    if (response) {
        const status = response.status || 0;
        switch (status) {
            case 401:
                window.alert('請登入您的帳號');

                if (fullPath.includes('admin')) {
                    router.replace({
                        path: '/login',
                        query: {
                            redirect: fullPath,
                        }
                    })
                } else {
                    router.push({
                        path: '/login',
                    })
                }

                break;
            case 403:
            case 404:
            case 422:
            case 500:
                break;
        }

        return Promise.reject(response.data);
    }

    if (!window.navigator.onLine) {
        // window.alert('There are some problems on network. Please reload again')
    } else {
        return Promise.reject(err);
    }
})

export default client;