import apiClient from '@/apis/apiClient';

async function postLogin({email, password}) {
    try {
        let result = await apiClient.post('/login', {
            email,
            password,
        });

        return result;
    } catch (err) {
        return err;
    }
}

async function deleteUser(userId) {
    try {
        await apiClient.delete('/deleteUser', {
            id: userId,
        });
    } catch (err) {
        console.log(err);
    } 
}

async function getLogout() {
    try {
        await apiClient.get('/logout');
    } catch (err) {
        console.log(err);
    } 
}

async function checkUser() {
    try {
        const result = await apiClient.get('/user/check');
        return result;
    } catch (err) {
        console.log(err);
    }
}

export {
    postLogin,
    getLogout,
    deleteUser,
    checkUser,
};