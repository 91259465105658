import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './en.json';
import zh_tw from './zh-tw.json';


Vue.use(VueI18n);

let storageLang = '';
if (localStorage.getItem('lang')) {
    storageLang = localStorage.getItem('lang');
}  else {
    storageLang = 'zh_tw';
}

const messages = {
    zh_tw,
    en
}

const i18n = new VueI18n({
    locale: storageLang,
    messages,
    fallbackLocale: 'zh_tw'
})

export default i18n;