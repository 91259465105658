import Vue from 'vue';

// function changeStr(str) {
//     return str.charAt(0).toUpperCase() + str.slice(1);
// }

const requireComponent = require.context('.', false, /Base+[\w]*\.vue$/)

requireComponent.keys().forEach(fileName => {

    // get component config
    const config = requireComponent(fileName)

    // get name of component
    const componentName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '');

    // register global component
    Vue.component(componentName, config.default || config);
})