<template>
    <footer class="position-relative d-flex overflow-hidden footer w-100">
        <b-container>
            <b-row>
                <b-col class="d-flex w-100 footer-row">
                    <div class="d-flex align-items-center logo-wrapper">
                        <div class="footer-name">{{ $t('Footer_corporation')}}</div>
                    </div>
                    
                    <div class="d-flex info-wrapper">
                        <div class="footer-split"></div>
                        <div class="footer-contact">
                            {{ $t('Footer_address')}}<br>
                            {{ $t('Footer_phone')}}<br>
                            {{ $t('Footer_email')}}
                        </div>
                    </div>
                
                    <div class="footer-social d-flex">
                        <a target="_blank" href="https://www.facebook.com/AIRACorporation" rel="noopener">
                            <div class="wdn-text-hidden">Aira人臉辨識_Facebook</div>
                            <div class="footer-link facebook"></div>
                        </a>
                        <!-- <div class="footer-link twitter"></div> -->
                        <a target="_blank" href="https://www.linkedin.com/company/airacorporation" rel="noopener">
                            <div class="wdn-text-hidden">Aira人臉辨識_Linkedin</div>
                            <div class="footer-link linkedin"></div>
                        </a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCI9-GayeGBcgxfQkcmYDoOg" rel="noopener">
                            <div class="wdn-text-hidden">Aira人臉辨識_Youtube</div>
                            <div class="footer-link youtube"></div>
                        </a>
                    </div>
                    
                    
                </b-col>
            </b-row>
            <b-row>
                <b-col class="d-flex w-100 footer-row">
                    <div class="d-flex">
                        <router-link to="/privacyterm">
                            <div class="footer-privacy">Privacy Policy and Terms of Use</div>
                        </router-link>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.footer-link{
    width: 44px;
    height: 44px;
    margin-left: 10px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
}

.facebook {
    background-image: url("~@/assets/img/footer/btn-social-fb.png");
}

.twitter {
    background-image: url("~@/assets/img/footer/btn-social-twitter.png");
}

.linkedin {
    background-image: url("~@/assets/img/footer/btn-social-linkedin.png");
}

.gold-partner {
    background-image: url("~@/assets/img/footer/gold-partner.png");
}

.youtube {
    background-image: url("~@/assets/img/footer/btn-social-youtube.png");
}

.footer {
    background-color: #334a67;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding-top: 80px;
    padding-bottom: 60px;
}

.footer-name {
    height: 45px;
    font-weight: 500;
    font-size: 32px;
    margin-top: 6px;
}

.footer-privacy {
    height: 45px;
    font-weight: 500;
    font-size: 15px;
    margin-top: 36px;
    color: #FFFFFF;
}

.footer-privacy a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}


.footer-row{
    justify-content: center;
}

.info-wrapper{
    padding: 0 15px;
}

.right-line {
    border-right: 1px solid white;
}

.footer-split{
    background-color: white;
    width: 1px;
    height: 80px;
    margin-left: 10px;
    margin-right: 10px;
}

.footer-contact{
    padding-top: 10px;
    padding-bottom: 10px;
}

.footer-social{
    align-items: center;
}

@media screen and (max-width: 1440px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 812px) {
    .footer-row{
        flex-direction: column;
    }

   .footer {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .footer .footer-name {
        height: auto;
        margin-left: 10px;
        font-size: 28px;
    }

    
    .info-wrapper{
        padding: 15px 0;
    }

    .footer-social{
        width: 100%;
        padding-top: 10px;
    }
    .footer-privacy {
        font-size: 15px;
    }
}

@media screen and (max-width: 576px) {
    .footer-privacy {
        font-size: 10px;
    }
}

</style>