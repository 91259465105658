import { render, staticRenderFns } from "./BaseNav.vue?vue&type=template&id=2cb5e310&scoped=true&"
import script from "./BaseNav.vue?vue&type=script&lang=js&"
export * from "./BaseNav.vue?vue&type=script&lang=js&"
import style0 from "./BaseNav.vue?vue&type=style&index=0&id=2cb5e310&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb5e310",
  null
  
)

export default component.exports