<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
    created() {},
    metaInfo() {
      return {
        title: '台灣製造的AI人臉辨識系統，Intel 認證的Gold Partner，多領域落地解決方案，在學校、健身房、辦公室、智慧建築、智慧長照和工地管理都有相關的成功案例。',
        titleTemplate: '%s | aira城智科技',
        meta: [
          { property: "og:title", content: "aira城智科技",vmid: 'og:title'},
          { property: "og:type", content: "website",vmid: 'og:type'},
          { property: "og:url", content: "https://www.aira.com.tw/",vmid: 'og:url'},
          { property: "og:image", content: "https://www.aira.com.tw/img/main-bg.032358f5.webp",vmid: 'og:image'},
          { property: "og:description", content: "aira城智科技是台灣自主研發的AI人臉辨識廠商，榮獲Intel認證為Gold Partner。不需要GPU即可進行人臉、人形和車牌辨識，並可應用在智慧建築、工地管理、辦公室、長照中心、學校、健身房等場域。",vmid: 'og:description'},
          { property: "DC.Title", content: "aira城智科技：",vmid: 'DC.Title'},
          { property: "DC.Subject", content: "人臉辨識/考勤/打卡/工地管理/學校/健身房/長照/Face Recognition/FR/Attendance/T&A/Construction/Long-term care/School/Gym",vmid: 'DC.Subject'},
          { property: "DC.Description", content:"aira城智科技是台灣自主研發的AI人臉辨識廠商，榮獲Intel認證為Gold Partner。不需要GPU即可進行人臉、人形和車牌辨識，並可應用在智慧建築、工地管理、辦公室、長照中心、學校、健身房等場域。",vmid: 'DC.Description'}
        
        ]
      }
    },

    data() {
        return {}
    },

    watch: {}
}
</script>

<style lang="scss">
@import 'assets/scss/style';

</style>
