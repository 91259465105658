<template>
    <router-link class="more" :to="leadTo">
        <slot><span>{{ $t('More') }}</span></slot>
        <b-icon class="more-icon" icon="chevron-right"></b-icon>
    </router-link>
</template>

<script>

export default {
    props: {
        leadTo: {
            type: String,
            default: '/',
        }
    },


    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.more {
    display: inline-block;
    color: $color_primary;
    text-decoration: none;
    transition: color 0.2s ease;
    .more-icon {
        position: relative;
        left: 0;
        transition: left 0.1s ease;
    }
    &:hover {
        color: $color_secondary;
        .more-icon {
            left: 6px;
        }
    }

}

</style>>

