import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/assets/i18n/lang';
import Notifications from 'vue-notification'
import '@/assets/icon';
import '@/assets/bootstrapvue';

import CoreuiVue from '@coreui/vue/src'

Vue.use(CoreuiVue)
Vue.use(Notifications)
Vue.config.productionTip = false;

// import Swiper styles
import 'swiper/swiper-bundle.css';

import '@/components/global/globalComponent';

Vue.config.performance = process.env.NODE_ENV !== "production";

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  }
}).$mount('#app')
