<template>
    <header class="fixed_top w-100">
        <b-container class="header_wrapper">
            <b-row class="nav_bar justify-content-between position-relative" align-v="center" no-gutters>
                <div class="py-2 d-lnline-flex logo">
                    <router-link to="/">
                    <div class="wdn-text-hidden">Aira_人臉辨識_Logo</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="92" height="40" style="margin-top: 3px;" viewBox="0 0 92 40">
                        <g fill="#FFF" fill-rule="evenodd">
                        <path
                            d="M19.307 25.022h-3.928c-5.794 0-6.874 2.346-6.874 4.497 0 2.188 1.312 4.077 4.542 4.077 5.067 0 6.26-3.355 6.26-7.898v-.676zm8.034 6.09c0 2.173 1.19 6.047 1.495 8.329h-8.48c-.243-.961-.438-2.897-.492-3.888C18.52 38.164 15.488 40 10.82 40 2.83 40 0 35.04 0 30.183c0-5.788 4.028-10.558 15.228-10.558h3.796v-2.338c0-2.629-.818-4.668-4.61-4.668-3.751 0-4.657 1.908-4.96 4.273H1.249c.343-5.076 3.4-10.225 13.3-10.225 7.63 0 12.793 2.616 12.793 10.574v13.87zM32.955 39.333L41.194 39.333 41.194 11.333 32.955 11.333zM46.812 19.481c0-5.32-.007-8.739-.125-10.967h8.323c.173 1.4.228 3.457.228 5.68 1.097-2.719 3.95-6.18 7.926-6.194v8.595c-4.782-.105-7.808 2.15-7.808 9.586V39.333h-8.544V19.481zM82.47 25.022h-3.929c-5.792 0-6.874 2.346-6.874 4.497 0 2.188 1.315 4.077 4.544 4.077 5.066 0 6.26-3.355 6.26-7.898v-.676zm8.035 6.09c0 2.173 1.19 6.047 1.495 8.329h-8.482c-.24-.961-.435-2.897-.49-3.888C81.684 38.164 78.652 40 73.983 40c-7.99 0-10.819-4.96-10.819-9.817 0-5.788 4.027-10.558 15.228-10.558h3.796v-2.338c0-2.629-.82-4.668-4.61-4.668-3.751 0-4.657 1.908-4.959 4.273h-8.207c.343-5.076 3.4-10.225 13.3-10.225 7.63 0 12.793 2.616 12.793 10.574v13.87zM32.955 8L41.194 8 41.194 0 32.955 0z" />
                        </g>
                    </svg>
                    </router-link>
                </div>

                <div 
                    class="mobile_header_nav"
                    :class="{'open_mobile_header_nav': menuStatus}">
                    <nav id="header_nav" class="nav_items py-2">
                        <!-- <a href="/?targetItem=application" class="nav_item link_application" @click.prevent="cilckNav('application')">{{ $t("Nav_application") }}</a> -->
                        <a href="/?targetItem=solution" class="nav_item link_solution" @click.prevent="cilckNav('solution')">{{ $t("Nav_solution") }}</a>
                        <a href="/products" class="nav_item link_products" @click.prevent="cilckNav('products')">{{ $t("Nav_products") }}</a>
                        <a href="/useCases" class="nav_item link_ussCases" @click.prevent="cilckNav('useCases')">{{ $t("Nav_useCases") }}</a>
                        <a href="/events" class="nav_item link_event" @click.prevent="cilckNav('event')">{{ $t("Nav_event") }}</a>
                        <a href="/?targetItem=partner" class="nav_item link_partner" @click.prevent="cilckNav('partner')">{{ $t("Nav_partner") }}</a>
                        <a href="/?targetItem=whyaira" class="nav_item link_whyaira" @click.prevent="cilckNav('whyaira')">{{ $t("Nav_whyaira") }}</a>
                        <router-link class="nav_item_button" to="/contactUs" >
                            <button class="call-to-act">
                                {{ $t("Nav_contactUs")}}
                            </button>
                        </router-link>
                    </nav>

                    <div class="py-2 d-flex lang">
                        <div @click="switchLocle('en')" class="lang-link" hreflang="en">EN</div> 
                        <div @click="switchLocle('zh_tw')" class="lang-link" hreflang="zh-Hant">中</div>
                    </div>
                </div>
                
                <div class="show-in-mobile">
                    <div
                        :class="{'d-block': menuStatus}" 
                        class="overlay">
                    </div>
                    <BaseHamburguerMenu
                        :menu-status.sync="menuStatus"
                        class="btn-mobile-menu" />
                </div>
            </b-row>
        </b-container>
    </header>
</template>

<script>
export default {
    name: 'basenav',

    data() {
        return {
            menuStatus: false,
        }
    },

    watch: {
        menuStatus(status) {
            const body = document.body;
            if (status) {
                body.classList.add('modal-open');
            } else {
                body.classList.remove('modal-open');
            }
        }
    },

    mounted() {
        if (this.$route.query.targetItem) {
            this.scrollToSpecific(this.$route.query.targetItem);
        }
    },

    methods: {
        cilckNav(targetItem) {
            document.body.classList.remove('modal-open');
            this.menuStatus = false;

            if (targetItem === 'products' || targetItem === 'useCases') { 
                switch(targetItem){
                    case 'products' : {
                        this.$router.push({path: '/products'})
                    }break;

                    case 'useCases' : {
                        this.$router.push({path: '/useCases'})
                    }break;
                }
                
            }
            else if (this.$route.name === 'Home') {
                this.scrollToSpecific(targetItem);
            }
            else {
                this.$router.push({
                    path: '/',
                    query: {
                        targetItem,
                    }
                })
            }
        },

        scrollToSpecific(targetItem) {
            let scrollYPosition = 0;
            let bufferArea = (window.screen.availWidth < 768) ? 70 : 10;

            scrollYPosition = document.getElementById(targetItem).offsetTop - bufferArea;
            
            window.scroll({
                behavior: "smooth",
                top: scrollYPosition,
            });
        },

        switchLocle(lang) {
            localStorage.setItem('lang', lang);
            this.$i18n.locale = lang;
        },
    }
}
</script>

<style lang="scss" scoped>
.header_wrapper{
    padding: 0 15px;
}

.fixed_top {
    position: fixed;
    top: 0;
    z-index: 1020;
    min-height: 64px;
    background-color: rgb(0 72 125 / 90%);
}

.logo{
    position: relative;
    left: -5px;
    z-index: 2;
    svg {
        width: 80%;
    }
}

.call-to-act {
    cursor: pointer;
    border: unset;
    background-color: #ff991a;
    color: #ffffff;
    height: 34px;
    width: 100px;
    border-radius:  5px;
    // font-size: 30px;
    font-weight: 400px;
    text-align: center;
    transition: 0.4s;
    &:hover {
        filter: brightness(1.2);
        color: #000000;
    }
}

.lang {
    justify-content: flex-end;
    word-spacing: 10px;
    margin-top: 6px;
}

.lang-link{
    position: relative;
    color: #fff;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    &:not(:last-child):after {
        content: '|';
        position: absolute;
        right: 0;
        top: 42%;
        transform: translateY(-50%);
        color: #fff;
    }
}

.btn-mobile-menu{
    position: absolute;
    right: 0;
    top: 7px;
}

.nav_items{
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    width: 100%;
    cursor: pointer;
    transform: translateX(-50%);
    min-width: 520px;
}

.nav_item_button {
    font-size: 16px;
    color: #fff;
    z-index: 10;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        display: inline-block;
        height: 1px;
        left: 20px;
        right: 20px;
        bottom: -5px;
        transition: opacity 0.3s ease, width 0.3s ease;
        background: #fff;
        width: 0;
        opacity: 0;
    }
    &:hover, &:active {
        &::after {
            width: calc(100% - 40px);
            opacity: 1;
        }
    }
}

.nav_item {
    font-size: 16px;
    color: #fff;
    z-index: 10;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    position: relative;
    margin-top: 7px;
    &::after {
        content: '';
        position: absolute;
        display: inline-block;
        height: 1px;
        left: 20px;
        right: 20px;
        bottom: -5px;
        transition: opacity 0.3s ease, width 0.3s ease;
        background: #fff;
        width: 0;
        opacity: 0;
    }
    &:hover, &:active {
        &::after {
            width: calc(100% - 40px);
            opacity: 1;
        }
    }
}

.mobile_header_nav{
    display: flex;
}

.show-in-mobile{
    display: none;
}

.overlay {
    position: fixed; 
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); 
    z-index: 2; 
    cursor: pointer; 
    display: none;
}

@media screen and (max-width: 1440px) {}
@media screen and (max-width: 1024px) {}

@media screen and (max-width: 950px) {
    .header_wrapper .nav_bar{
        min-height: 49px;
    }

    .show-in-mobile{
        display: block;
    }

    .logo {
        position: absolute;
        left: -15px;
        top: 0;
        transform: scale(0.8);
        padding: 3px 0 3px 10px !important;
        width: 130px;

        svg {
            width: 80%;
        }
    }

    .mobile_header_nav {
        background: #00487d;
        width: 280px;
        position: fixed;
        top: 0;
        right: -280px;
        height: 100vh;
        display: flex;
        z-index: 10;
        flex-direction: column;
        transition: right 0.3s ease;
        &.open_mobile_header_nav {
            right: 0;
        }
    }

    .nav_items {
        position: initial;
        left: unset;
        transform: none;
        flex-direction: column;
        display: flex;
        margin-top: 40px;
        min-width: auto;
    }

    .nav_item {
        padding: 5px 25px;
        position: relative;
        margin-top: 7px;
        text-align: end;
        &:hover, &:active {
            &::after {
                opacity: 0;
            }
        }
    }

    .nav_item_button {
        padding: 10px 13px;
        position: relative;
        text-align: end;
        &:hover, &:active {
            &::after {
                opacity: 0;
            }
        }
    }

    .call-to-act {
        width: 100px;
    }

    .lang {
        justify-content: flex-end;
        word-spacing: 10px;
        margin-top: 6px;
        position: absolute;
        top: 80vh;
        right: 5px;
    }

    .fixed_top {
        min-height: 40px;
    }
}

@media screen and (max-width: 576px) {}

</style>